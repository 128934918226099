.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

@media only screen and (min-width: 801px) {
  .container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    align-items: left;

    display: grid;
    gap: 15px;

    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .movie-cover {
    width: 100%;
    min-width: 130px;
  }

  .hero-title {
    font-size: 72px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 800px) {
  .container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    align-items: left;

    display: grid;
    gap: 15px;

    grid-template-columns: 1fr 1fr 1fr;
  }

  .movie-cover {
    width: 100%;
    min-width: 130px;
    
  }

  .hero-title {
    font-size: 45px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 600px) {
  .container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    align-items: left;

    display: grid;
    gap: 15px;

    grid-template-columns: 1fr 1fr;
  }

  .movie-cover {
    width: 100%;
    min-width: 130px;
  }

  .hero-title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    align-items: left;

    display: grid;
    gap: 15px;

    grid-template-columns: 1fr;
  }

  .movie-cover {
    width: 100%;
    min-width: 130px;
  }

  .hero-title {
    font-size: 30px;
  }
}

.max-center {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.nav-container {
  margin-left: 3%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.movie-placeholder {
  width: 100%;
  height: 0;
  min-width: 130px;
  background-color: rgb(235, 231, 231);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border: rgb(99, 97, 97);
  border-style: solid;
  border-width: 1px;
  padding-bottom: 75%;
  padding-top: 75%;
}

.hero {
  position: relative;
  min-height: 500px;
  color: white;
  text-shadow: 2px 2px 0px #000000; /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  background-size: cover;
  background-color: #1A374D;
  display: flex;
  align-items: flex-end;
  padding-bottom: 1%;
  margin-bottom: 15px;
  margin-top: 5px;
}

.hero-button {
  color: white;
  border: none;
  outline: 1px solid white;
  width: 90px;
}

.hero-content {
  max-width: 85%;
  padding-bottom: 60px;
}

.trailer-button {
  bottom: 25px;
  position: absolute;
}

.sort-button {
  bottom: 25px;
  margin-left: 2px;
}

.search-button {
  width: 64px;
}

.button-close {
  position: absolute;
  z-index: 10;
  bottom: 25px;
}

.youtube-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.search-form {
  min-width: 255px;
  display: flex;
  align-items: flex-end;
  justify-content: right;
}

.align_right {
  text-align: right;
  color: #000000;
}

.moviedb {
  color: #000000;
  text-decoration: none;
}

.actor-website-button {
  width: auto;
}

.button-form {
  min-width: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}